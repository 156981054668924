import BaseSelect from '@/components/common/base-select/base-select.vue'
import { formatDate } from '@/utils/auth/common'

// 查询form
export const dataForm = (content) => {
  return [
    {
      label: '业务编号',
      prop: 'businessNo',
      attrs: {
        placeholder: '请输入',
        maxlength: 20
      }
    },
    {
      label: '供应商',
      prop: 'gysCompanyName',
      rules: [{ required: false, message: '请输入', trigger: 'blur' }],
      attrs: {
        placeholder: '请输入',
        options: content.dropOptions,
        selectedField: ['keyId', 'enterpriseName'],
        filterable: true
      },
      tag: BaseSelect,
      on: {
        change: (data) => {
          content.changeQueryGys(data)
        }
      }
    },
    {
      label: '风险类型',
      prop: 'riskTypeId',
      attrs: {
        options: content.list,
        selectedField: ['riskTypeId', 'riskTypeName'],
        placeholder: '请选择'
      },
      tag: BaseSelect
    }
  ]
}
// 表格form
export const dataTable = () => {
  return [
    {
      label: '序号',
      prop: 'index'
    },
    {
      label: '业务编号',
      prop: 'businessNo'
    },
    {
      label: '供应商',
      prop: 'gysCompanyName',
      minWidth: '120px',
      align: 'left'
    },
    {
      label: '风险类型',
      prop: 'riskTypeName'
    },
    {
      label: '预警条件',
      prop: 'warningConditions',
      'show-overflow-tooltip': true

    },
    {
      label: '监测值',
      prop: 'checkValue',
      'show-overflow-tooltip': true
      // formatter: row => {
      //   return (row.checkValue ? row.checkValue.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '')
      // }

    },
    {
      label: '监测时间',
      prop: 'checkTime',
      formatter: row => {
        return formatDate(row.checkTime, 'YY-MM-DD')
      }
    },
    {
      label: '提醒人员',
      prop: 'businessManager'
    },
    // {
    //   label: '阅读状态',
    //   prop: 'readStatus',
    //   formatter: row => {
    //     return Number.parseInt(row.readStatus) === 0 ? '未读' : '已读'
    //   }
    // },
    {
      label: '已阅人员',
      prop: 'readManager'
    },
    {
      label: '操作',
      prop: 'action'
    }
  ]
}
