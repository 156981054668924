<template>
  <div class="risk-detection">
    <!-- 查询 -->
    <base-form
      :componentList="formConfig"
      :formAttrs="{
        model: queryParas,
        labelWidth: '90px'
      }"
      class="formStyle"
      @handleFilter="handleFilter"
      @clearParams="clearParams"
      ref="formData">
    </base-form>
    <!-- 表格 -->
    <base-table
    class="main-page-table"
      :columns="columns"
      :showPage="true"
      :tableAttrs="{
        data: tableData,
        stripe: true,
      }"
      ref="tableData"
      :api="api"
      :getApi="'getPage'"
      :queryParas.sync="queryParas"
      :loadCount.sync="loadCount"
      :dataSource.sync="tableData"
      :webPage="false">
      <template slot="index" slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>
      <template slot="action" slot-scope="scope">
        <icon-button  @click="readStatus(scope.row)"  content="查看并标识为已阅" icon="iconfont iconchakan"/>
      </template>
    </base-table>
     <!-- 详情弹出框  -->
    <detection-detalis ref="detalis" :visible.sync="visibleDetail" :name='name' :detailInfo='detailInfo'/>
  </div>
</template>
<script>
import IconButton from '@/components/common/button/icon-button/icon-button'
import baseForm from '@/components/common/base-form/base-form.vue'
import { dataForm, dataTable } from './utils/config'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import { riskfxAPi } from '@/api/riskApi'
import { formatDate } from '@/utils/auth/common'
import { supplierApi } from '@/api/companyApi'
import DetectionDetalis from './components/detection-detalis.vue'
export default {
  name: 'riskDetection',
  components: { baseForm, BaseTable, IconButton, DetectionDetalis },
  data () {
    return {
      queryParas: {
        pageSize: 10,
        pageIndex: 1,
        tenantId: 1
      },
      loadCount: 0,
      tableData: [],
      account: 0,
      list: [],
      detailInfo: {},
      visibleDetail: false,
      count: 0,
      name: '',
      dropOptions: []
    }
  },
  computed: {
    formConfig () {
      return dataForm(this)
    },
    columns () {
      return dataTable(this)
    },
    api () {
      return riskfxAPi
    }
  },
  mounted () {
    this.handleFilter()
    this.setSelectRsiktype()
    this.gysList()
  },
  watch: {
    // 监听已读，已读状态未读变已读刷新列表
    visibleDetail (val) {
      if (!val && this.$refs.detalis.isReader) {
        this.handleFilter()
      }
    }
  },
  methods: {
    handleFilter () {
      this.queryParas.pageIndex = 1
      this.loadCount++
    },
    clearParams () {
      this.queryParas = {
        pageSize: 10,
        pageIndex: 1
      }
    },
    // 风险类型下拉获取
    setSelectRsiktype () {
      this.api.getRiskTypeSelectData()
        .then(res => {
          this.list = res.data
        })
    },
    // 获取供应商数据
    gysList () {
      supplierApi.getList().then(res => {
        if (res.success) {
          this.dropOptions = res.data
        }
      })
    },
    // 查询增加选项的name
    changeQueryGys (data) {
      if (data) {
        const obj = this.dropOptions.filter(item => item.keyId === data)
        this.$set(this.queryParas, 'gysCompanyName', obj[0].enterpriseName)
      } else {
        this.$set(this.queryParas, 'gysCompanyName', '')
      }
    },
    // 点击查看详情
    readStatus (data) {
      this.name = '融资主体：' + data.gysCompanyName
      this.detailInfo = data
      this.detailInfo.checkTime = formatDate(data.checkTime, 'YY-MM-DD')
      this.detailInfo.readStatus = data.readStatus === '0' ? '未读' : '已读'
      this.visibleDetail = true
    }
  }
}
</script>
<style lang="scss" scoped>
.risk-detection {
  height: 100%;
}
</style>
